import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/components/DashboardLayout';
import MainLayout from 'src/components/MainLayout';
import CampaignList from 'src/pages/CampaignList';
import AdsetList from 'src/pages/AdsetList';
import Dashboard from 'src/pages/Dashboard';
import NotFound from 'src/pages/NotFound';
import Finance from 'src/pages/Finance';
import Login from './pages/Login';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'campaigns', element: <CampaignList /> },
      { path: 'campaigns/:id', element: <AdsetList /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'billing', element: <Finance /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: 'login',
    element: <Login />
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
