import { checkIfAccessTokenOkInResponse } from 'src/utils/user';
import { getCookie } from 'src/utils/cookie';
import campaigns from '../_mocks_/campaigns-info.json';
import campaignInsights from '../_mocks_/campaign-insights.json';
import {
  API_DOMAIN,
  API_VERSION,
  ACCESS_TOKEN,
  DEVELOP_MODE
} from '../config/main.json';

const loadCampaignData = async (adAccount, abortSignal, onlyFavorites) => {
  let data = [];

  const onlyFavoritesBool = typeof onlyFavorites !== 'undefined' && onlyFavorites;
  const type = (typeof onlyFavorites !== 'undefined' && onlyFavorites) ? 'favorites' : 'active,paused';

  try {
    const LCData = localStorage.getItem(`campaign-data-${adAccount}`);
    const LCDataDate = localStorage.getItem(`campaign-data-${adAccount}-date`);
    const favoritesSavedOnly = localStorage.getItem(`campaign-favorites-only-${adAccount}`);

    const currentDate = new Date().getTime();
    if (LCData === null || (currentDate - LCDataDate) > 300000 || (favoritesSavedOnly && !onlyFavoritesBool)) {
      if (!DEVELOP_MODE) {
        data = await fetch(`${API_DOMAIN}/v${API_VERSION}/campaigns/${type}?access-token=${ACCESS_TOKEN}&at=${getCookie('fbat')}&adaccount=${adAccount}`, { signal: abortSignal });

        if (data.ok) {
          data = await data.json();

          if (checkIfAccessTokenOkInResponse(data) === false) {
            window.location = '/login';
            return false;
          }
        } else {
          data = false;
        }
      } else {
        data = campaigns;
      }

      if (data) {
        data = Object.values(data).map((entry) => ({ ...entry, insightsLoaded: false }));

        localStorage.setItem(`campaign-data-${adAccount}`, JSON.stringify(data));
        localStorage.setItem(`campaign-data-${adAccount}-date`, currentDate);
        if (typeof onlyFavorites !== 'undefined' && onlyFavorites) {
          localStorage.setItem(`campaign-favorites-only-${adAccount}`, 1);
        }
      }
    } else {
      data = JSON.parse(LCData);
    }
  } catch (error) {
    console.log(error);
  }

  return data;
};

const loadCampaignInsights = async (adAccount, id, from, to, abortSignal) => {
  let data = [];

  try {
    const daterange = `${from}-${to}`;
    const LCData = localStorage.getItem(`campaign-${id}-insights-${daterange}-${adAccount}`);
    const LCDataDate = localStorage.getItem(`campaign-${id}-insights-${daterange}-${adAccount}-date`);

    const currentDate = new Date().getTime();
    if (LCData === null || (currentDate - LCDataDate) > 300000) {
      if (!DEVELOP_MODE) {
        data = await fetch(`${API_DOMAIN}/v${API_VERSION}/campaign/${id}/${daterange}?access-token=${ACCESS_TOKEN}&at=${getCookie('fbat')}&adaccount=${adAccount}`, { signal: abortSignal });

        if (data.ok) {
          data = await data.json();

          if (checkIfAccessTokenOkInResponse(data) === false) {
            window.location = '/login';
            return false;
          }
        } else {
          data = false;
        }
      } else {
        data = campaignInsights;
      }

      if (data) {
        data.insightDate = daterange;

        localStorage.setItem(`campaign-${id}-insights-${daterange}-${adAccount}`, JSON.stringify(data));
        localStorage.setItem(`campaign-${id}-insights-${daterange}-${adAccount}-date`, currentDate);
      }
    } else {
      data = JSON.parse(LCData);
    }
  } catch (error) {
    console.log(error);

    return false;
  }

  return data;
};

const changeLocalValue = (data, id, name, value) => {
  Object.entries(data).forEach((entry) => {
    if (entry[1].id === id) {
      entry[1][name] = value;
    }
  });

  localStorage.setItem('campaign-data', JSON.stringify(data));

  return data;
};

const insertCampaignInsights = (data, id, insights) => {
  data.forEach((entry, index) => {
    if (entry.id === id) {
      data[index] = {
        ...entry,
        ...insights
      };
    }
  });

  data = changeLocalValue(data, id, 'insightsLoaded', true);

  return data;
};

const changeCampaignState = async (adAccount, data, id, cstate) => {
  if (!DEVELOP_MODE) {
    const formData = new FormData();
    formData.append('status', cstate);

    const requestOptions = {
      method: 'POST',
      body: formData
    };
    const url = `${API_DOMAIN}/v${API_VERSION}/campaign/${id}?access-token=${ACCESS_TOKEN}&at=${getCookie('fbat')}&adaccount=${adAccount}`;
    const response = await fetch(url, requestOptions);

    if (!response.ok) return false;
  }

  return changeLocalValue(data, id, 'status', cstate);
};

const changeCampaignFavorite = async (adAccount, data, id, fState) => {
  if (!DEVELOP_MODE) {
    const formData = new FormData();
    formData.append('id', id);

    let method = 'unsetFavorite';
    if (fState) {
      method = 'setFavorite';
    }

    const requestOptions = {
      method: 'POST',
      body: formData
    };
    const url = `${API_DOMAIN}/v${API_VERSION}/campaigns/${method}?access-token=${ACCESS_TOKEN}&at=${getCookie('fbat')}&adaccount=${adAccount}`;
    const response = await fetch(url, requestOptions);

    if (!response.ok) return false;
  }

  return changeLocalValue(data, id, 'favorite', fState);
};

const unsetInsights = (data) => {
  Object.entries(data).forEach((entry) => {
    entry[1].insightsLoaded = false;
    entry[1].insightsLoading = false;
  });

  return data;
};

const CampaignReducer = (state, action) => {
  switch (action.type) {
    case 'CAMPAIGN_LOADING':
      return {
        ...state,
        loaded: false,
        allLoaded: false,
        dataAbort: action.payload.abort
      };
    case 'SET_CAMPAIGN_DATA':
      return {
        ...state,
        loaded: true,
        allLoaded: true,
        initialized: true,
        data: action.payload.data
      };
    case 'SET_FAVORITES_DATA':
      return {
        ...state,
        loaded: true,
        allLoaded: false,
        initialized: true,
        data: action.payload.data
      };
    case 'CAMPAIGN_INSIGHTS_LOADING': {
      const processedData = changeLocalValue(state.data, action.payload.id, 'insightsLoading', true);

      return {
        ...state,
        data: processedData
      };
    }
    case 'RESET_CAMPAIGN_DATA': {
      return {
        ...state,
        loaded: false,
        allLoaded: false,
        initialized: false,
        insightsLoaded: false,
        data: {}
      };
    }
    case 'SET_CAMPAIGN_INSIGHTS': {
      const processedData = insertCampaignInsights(state.data, action.payload.id, action.payload.insights);

      return {
        ...state,
        insightsLoaded: true,
        data: processedData
      };
    }
    case 'SET_CAMPAIGN_STATE':
      return {
        ...state,
        data: action.payload.processedData
      };
    case 'SET_CAMPAIGN_FAVORITE':
      return {
        ...state,
        data: action.payload.processedData
      };
    case 'UNLOAD_INSIGHTS': {
      const processedData = unsetInsights(state.data);

      return {
        ...state,
        data: processedData
      };
    }
    default:
      return state;
  }
};

export {
  CampaignReducer, loadCampaignData, loadCampaignInsights, changeCampaignState, changeCampaignFavorite
};
