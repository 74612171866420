import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Grid
} from '@mui/material';
import AmountSpent from 'src/components/dashboard/AmountSpent';
import Campaigns from 'src/components/dashboard/Campaigns';
import Budget from 'src/components/dashboard/Budget';
import Revenue from 'src/components/dashboard/Revenue';
import ActiveAds from 'src/components/dashboard/ActiveAds';
import { useAppState } from 'src/contexts/AppState';
import { useEffect } from 'react';
import { loadAdAccountInsights, loadAdAccountInfo } from 'src/reducers/adaccountReducer';

const Dashboard = () => {
  const [state, dispatch] = useAppState();

  useEffect(async () => {
    if (state.adaccount.selectedId !== 0) {
      if (typeof state.adaccount.insightsAbort !== 'undefined') state.adaccount.insightsAbort.abort();

      const abortC = new AbortController();
      const { signal } = abortC;
      dispatch({ type: 'LOADING_INSIGHTS', payload: { abort: abortC } });

      const AAInsights = await loadAdAccountInsights(state.adaccount.selectedId, state.daterange.from, state.daterange.to, signal);
      if (AAInsights) {
        dispatch({
          type: 'SET_INSIGHTS',
          payload: {
            data: AAInsights
          }
        });
      } else {
        dispatch({
          type: 'SHOW_NOTIFICATION',
          payload: {
            type: 'error',
            message: 'Nu sau putut încărca statisticile contului',
          }
        });
      }
    }
  }, [state.daterange.from, state.daterange.to, state.adaccount.selectedId]);

  useEffect(async () => {
    if (state.adaccount.selectedId !== 0) {
      if (typeof state.adaccount.infoAbort !== 'undefined') state.adaccount.infoAbort.abort();

      const abortC = new AbortController();
      const { signal } = abortC;
      dispatch({ type: 'LOADING_INFO', payload: { abort: abortC } });

      const AAInfo = await loadAdAccountInfo(state.adaccount.selectedId, signal);
      if (AAInfo) {
        dispatch({
          type: 'SET_INFO',
          payload: {
            data: AAInfo
          }
        });
      } else {
        dispatch({
          type: 'SHOW_NOTIFICATION',
          payload: {
            type: 'error',
            message: 'Nu sa putut încărca informațille despre cont',
          }
        });
      }
    }
  }, [state.adaccount.selectedId]);

  return (
    <>
      <Helmet>
        <title>{window.config.APP_NAME}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={3}
              sm={6}
              xl={3}
              xs={12}
            >
              <AmountSpent />
            </Grid>
            <Grid
              item
              lg={3}
              sm={6}
              xl={3}
              xs={12}
            >
              <Revenue />
            </Grid>
            <Grid
              item
              lg={3}
              sm={6}
              xl={3}
              xs={12}
            >
              <Budget />
            </Grid>
            <Grid
              item
              lg={3}
              sm={6}
              xl={3}
              xs={12}
            >
              <ActiveAds sx={{ height: '100%' }} />
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              xl={12}
              xs={12}
            >
              <Campaigns />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Dashboard;
