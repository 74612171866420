import { useState } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField,
  Typography,
  IconButton
} from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import { Close } from '@mui/icons-material';
import { func } from 'prop-types';
import { setBudget } from 'src/reducers/adaccountReducer';
import { useAppState } from '../../contexts/AppState';

const BudgetChange = ({
  handleHideBudgetChange, showNotification, setMessage, setNotificationType
}) => {
  const [state, dispatch] = useAppState();
  const [formDisabled, setFormDisabled] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleBudgetChange = async (event) => {
    event.preventDefault();
    setFormDisabled(true);

    const newBudgetValue = parseFloat(event.target.newBudget.value);

    if (newBudgetValue > state.adaccount.info.amount_spent) {
      const newState = await setBudget(state.adaccount.selectedId, state.adaccount.info, newBudgetValue);

      if (newState !== false) {
        await dispatch({
          type: 'SET_BUDGET',
          payload: {
            newInfoState: newState
          }
        });

        setMessage('Bugetul a fost modificat cu success!');
        setNotificationType('success');
      } else {
        setMessage('Bugetul nu s-a putut modifica');
        setNotificationType('error');
      }

      showNotification();

      setFormDisabled(false);
      handleHideBudgetChange(true);
    } else {
      setError(true);
      setErrorMessage('Limita setată este mai mică decât suma cheltuită');
      setFormDisabled(false);
    }
  };

  const handleChange = (event) => {
    const newBudgetValue = parseFloat(event.target.value);

    if (newBudgetValue > state.adaccount.info.amount_spent) {
      setError(false);
      setErrorMessage('');
    } else {
      setError(true);
      setErrorMessage('Limita setată este mai mică decât suma cheltuită');
    }
  };

  return (
    <>
      <Card sx={{ mt: 4 }}>
        <CardHeader
          sx={{ pb: 0 }}
          action={(
            <IconButton
              aria-label="Închide"
              style={{ marginTop: '-10px' }}
              onClick={handleHideBudgetChange}
            >
              <Close />
            </IconButton>
          )}
          title={(
            <Typography color="textSecondary" gutterBottom variant="h6">
              MODIFICĂ BUGETUL
            </Typography>
          )}
        />
        <CardContent sx={{ pt: 1 }}>
          <form onSubmit={handleBudgetChange}>
            <Grid container sx={{ pt: 1 }} alignItems="center">
              <Grid item flexGrow={1}>
                <TextField
                  type="number"
                  id="new-budget"
                  name="newBudget"
                  autoFocus
                  label="Introduce buget nou"
                  disabled={formDisabled}
                  defaultValue={state.adaccount.info.spend_cap}
                  size="small"
                  style={{ width: '100%' }}
                  error={error}
                  onChange={handleChange}
                  sx={{
                    mb: 1
                  }}
                  inputProps={{
                    step: 50
                  }}
                />
              </Grid>
              <Grid item textAlign="right" flexGrow={1}>
                <Button
                  variant="contained"
                  startIcon={<Edit />}
                  color="secondary"
                  type="submit"
                  disabled={formDisabled}
                  sx={{
                    mb: 1
                  }}
                >
                  Schimbă
                </Button>
              </Grid>
            </Grid>
            {errorMessage !== '' && error && (
              <Typography variant="body1" color="error" fontSize={12}>
                {errorMessage}
              </Typography>
            )}
          </form>
        </CardContent>
      </Card>
    </>
  );
};

BudgetChange.propTypes = {
  handleHideBudgetChange: func,
  showNotification: func,
  setMessage: func,
  setNotificationType: func
};

export default BudgetChange;
