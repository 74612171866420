import { IconButton } from '@mui/material';
import { GetApp } from '@mui/icons-material';
import { string, func } from 'prop-types';

const DownloadAdsetButton = ({ adsetId, handleLoadInfo }) => (
  <IconButton
    size="small"
    onClick={() => {
      handleLoadInfo(adsetId);
    }}
  >
    <GetApp />
  </IconButton>
);

DownloadAdsetButton.propTypes = {
  adsetId: string.isRequired,
  handleLoadInfo: func.isRequired
};

export default DownloadAdsetButton;
