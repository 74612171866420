import { Snackbar, Alert } from '@mui/material';
import {
  bool,
  func,
  number,
  string
} from 'prop-types';

const Notification = ({
  type,
  duration,
  message,
  open,
  setOpen
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={duration}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Alert severity={type} variant="filled">
        {message}
      </Alert>
    </Snackbar>
  );
};

Notification.defaultProps = {
  type: 'success',
  duration: 4000
};

Notification.propTypes = {
  type: string,
  duration: number,
  message: string.isRequired,
  open: bool.isRequired,
  setOpen: func.isRequired
};

export default Notification;
