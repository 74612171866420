import { IconButton } from '@mui/material';
import { GetApp } from '@mui/icons-material';
import { string, func } from 'prop-types';

const DownloadCampaignButton = ({ campaignId, handleLoadInfo }) => (
  <IconButton
    size="small"
    onClick={() => {
      handleLoadInfo(campaignId);
    }}
  >
    <GetApp />
  </IconButton>
);

DownloadCampaignButton.propTypes = {
  campaignId: string.isRequired,
  handleLoadInfo: func.isRequired
};

export default DownloadCampaignButton;
