import {
  Avatar,
  Box, Button, Card,
  CardContent,
  Grid, LinearProgress,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { orange } from '@mui/material/colors';
import EditIcon from '@mui/icons-material/Edit';
import InsertChartIcon from '@mui/icons-material/InsertChartOutlined';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import { bool, func } from 'prop-types';
import ReactPlaceholder from 'react-placeholder/lib';
import 'react-placeholder/lib/reactPlaceholder.css';
import { useAppState } from 'src/contexts/AppState';
import { resetBudget } from 'src/reducers/adaccountReducer';
import Notification from '../utils/Notification';

const Budget = (props) => {
  const [state, dispatch] = useAppState();
  const [showNotificaition, setShowNotificaition] = useState(false);
  const [notificationType, setNotificationType] = useState('success');
  const [notificationMessage, setNotificationMessage] = useState('');

  const percentage = (state.adaccount.info.amount_spent * 100) / state.adaccount.info.spend_cap;

  const { forceShowButton, showChangeBudget, handleShowBudgetChange } = props;

  const handleBudgetReset = async () => {
    const newInfoState = await resetBudget(state.adaccount.selectedId, state.adaccount.info);

    if (newInfoState !== false) {
      dispatch({
        type: 'RESET_BUDGET',
        payload: {
          newInfoState
        }
      });

      setNotificationMessage('Bugetul a fost resetat cu success!');
      setNotificationType('success');
      setShowNotificaition(true);
    } else {
      setNotificationMessage('Bugetul nu s-a putut resetat.');
      setNotificationType('error');
      setShowNotificaition(true);
    }
  };

  const useCardStyles = makeStyles((theme) => ({
    root: {
      [theme.breakpoints.between('lg', 'xl')]: {
        position: 'relative',
        marginTop: '10px',
        paddingTop: '5px',
        overflow: 'visible !important',
      }
    }
  }));

  const cardClasses = useCardStyles();

  const useAvatarStyles = makeStyles((theme) => ({
    root: {
      [theme.breakpoints.between('lg', 'xl')]: {
        position: 'absolute !important',
        top: '-10px',
        right: '-10px'
      }
    }
  }));

  const avatarClasses = useAvatarStyles();

  return (
    <>
      <Card
        sx={{ height: '100%' }}
        className={cardClasses.root}
      >
        <CardContent>
          <Grid
            container
            spacing={3}
            sx={{ justifyContent: 'space-between' }}
            flexWrap="nowrap"
          >
            <Grid item>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="h6"
              >
                BUGETUL CURENT
              </Typography>
              <Typography
                color="textPrimary"
                variant="h4"
              >
                <ReactPlaceholder showLoadingAnimation type="text" ready={state.adaccount.infoLoaded} rows={1} color="#e0e0e0">
                  <Box>
                    {`${state.adaccount.info.amount_spent} / ${state.adaccount.info.spend_cap}`}
                    &nbsp;
                    <small style={{ color: '#aaa', fontSize: '13px' }}>RON</small>
                  </Box>
                </ReactPlaceholder>
              </Typography>
            </Grid>
            <Grid item>
              <Avatar
                sx={{
                  backgroundColor: orange[600],
                  height: 56,
                  width: 56
                }}
                className={avatarClasses.root}
              >
                <InsertChartIcon />
              </Avatar>
            </Grid>
          </Grid>
          <Box sx={{ pt: 3 }}>
            <ReactPlaceholder showLoadingAnimation type="text" ready={state.adaccount.infoLoaded} rows={1} color="#e0e0e0">
              <LinearProgress
                value={percentage}
                variant="determinate"
              />
            </ReactPlaceholder>
          </Box>
          <Box sx={{ pt: 3 }} display="flex" justifyContent="flex-end">
            { showChangeBudget && (
              <Button
                color="secondary"
                variant="contained"
                onClick={handleShowBudgetChange}
                sx={{ mr: 2 }}
              >
                <EditIcon />
                &nbsp;MODIFICĂ
              </Button>
            )}
            { ((state.adaccount.infoLoaded && (state.adaccount.info.spend_cap - state.adaccount.info.amount_spent < 100)) || forceShowButton) && (
            <Button
              color="primary"
              variant="contained"
              onClick={handleBudgetReset}
              disabled={(state.adaccount.info.amount_spent === 0)}
            >
              <RotateLeftIcon />
              &nbsp;RESETARE
            </Button>
            )}
          </Box>
        </CardContent>
      </Card>
      <Notification type={notificationType} message={notificationMessage} open={showNotificaition} setOpen={setShowNotificaition} />
    </>
  );
};

Budget.propTypes = {
  forceShowButton: bool,
  showChangeBudget: bool,
  handleShowBudgetChange: func
};

export default Budget;
