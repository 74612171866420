import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { green, red } from '@mui/material/colors';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import MoneyIcon from '@mui/icons-material/Money';
import ReactPlaceholder from 'react-placeholder/lib';
import 'react-placeholder/lib/reactPlaceholder.css';
import { useAppState } from 'src/contexts/AppState';

const RevenueCompare = () => {
  const [state] = useAppState();

  let changePercentageValue = (((state.adaccount.insights.purchaseValue_previous * 100) / state.adaccount.insights.purchaseValue) - 100).toFixed();
  const directionValue = changePercentageValue < 0;
  changePercentageValue = Math.abs(changePercentageValue);

  let changePercentageCount = (((state.adaccount.insights.purchaseCount_previous * 100) / state.adaccount.insights.purchaseCount) - 100).toFixed();
  const directionCount = changePercentageCount < 0;
  changePercentageCount = Math.abs(changePercentageCount);

  return (changePercentageValue !== 0 || changePercentageCount !== 0) ? (
    <>
      {
        (directionValue) ? <ArrowUpwardIcon sx={{ color: green[900] }} /> : <ArrowDownwardIcon sx={{ color: red[900] }} />
      }
      <Typography
        sx={{
          color: (directionValue) ? green[900] : red[900],
          mr: 1
        }}
        variant="body2"
      >
        {changePercentageValue}
        %
      </Typography>
      &nbsp;|&nbsp;
      {
        (directionCount) ? <ArrowUpwardIcon sx={{ color: green[900] }} /> : <ArrowDownwardIcon sx={{ color: red[900] }} />
      }
      <Typography
        sx={{
          color: (directionCount) ? green[900] : red[900],
          mr: 1
        }}
        variant="body2"
      >
        {changePercentageCount}
        %
      </Typography>
      <Typography
        color="textSecondary"
        variant="caption"
      >
        Față de perioada anterioară
      </Typography>
    </>
  ) : (
    <Typography
      color="textSecondary"
      variant="caption"
    >
      Nu au fost schimbări
    </Typography>
  );
};

const Revenue = (props) => {
  const [state] = useAppState();

  const useCardStyles = makeStyles((theme) => ({
    root: {
      [theme.breakpoints.between('lg', 'xl')]: {
        position: 'relative',
        marginTop: '10px',
        paddingTop: '5px',
        overflow: 'visible !important',
      }
    }
  }));

  const cardClasses = useCardStyles();

  const useAvatarStyles = makeStyles((theme) => ({
    root: {
      [theme.breakpoints.between('lg', 'xl')]: {
        position: 'absolute !important',
        top: '-10px',
        right: '-10px'
      }
    }
  }));

  const avatarClasses = useAvatarStyles();

  return (
    <Card
      sx={{ height: '100%' }}
      {...props}
      className={cardClasses.root}
    >
      <CardContent>
        <Grid
          container
          spacing={3}
          sx={{ justifyContent: 'space-between' }}
          flexWrap="nowrap"
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              TOTAL PROFIT
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              <ReactPlaceholder showLoadingAnimation type="text" ready={state.adaccount.insightsLoaded} rows={1} color="#e0e0e0">
                <Box>
                  {state.adaccount.insights.purchaseValue}
                  &nbsp;
                  <small style={{ color: '#aaa', fontSize: '13px' }}>RON</small>
                  &nbsp;|&nbsp;
                  {state.adaccount.insights.purchaseCount}
                </Box>
              </ReactPlaceholder>
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: green[600],
                height: 56,
                width: 56
              }}
              className={avatarClasses.root}
            >
              <MoneyIcon />
            </Avatar>
          </Grid>
        </Grid>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            pt: 2
          }}
          flexWrap="wrap"
        >
          <ReactPlaceholder showLoadingAnimation type="text" ready={state.adaccount.insightsLoaded} rows={1} color="#e0e0e0">
            <RevenueCompare />
          </ReactPlaceholder>
        </Box>
      </CardContent>
    </Card>
  );
};

export default Revenue;
