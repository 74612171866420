import { checkIfAccessTokenOkInResponse } from 'src/utils/user';
import { getCookie } from 'src/utils/cookie';
import adsets from '../_mocks_/adset-info.json';
import adsetInsights from '../_mocks_/adset-insights.json';
import {
  API_DOMAIN,
  API_VERSION,
  ACCESS_TOKEN,
  DEVELOP_MODE
} from '../config/main.json';

const loadAdsetData = async (adAccount, id, abortSignal) => {
  let data = [];

  const type = 'active,paused';

  try {
    const LCData = localStorage.getItem(`adset-${id}-data-${adAccount}`);
    const LCDataDate = localStorage.getItem(`adset-${id}-data-${adAccount}-date`);

    const currentDate = new Date().getTime();
    if (LCData === null || (currentDate - LCDataDate) > 300000) {
      if (!DEVELOP_MODE) {
        data = await fetch(`${API_DOMAIN}/v${API_VERSION}/adsets/${id}/${type}?access-token=${ACCESS_TOKEN}&at=${getCookie('fbat')}&adaccount=${adAccount}`, { signal: abortSignal });

        if (data.ok) {
          data = await data.json();

          if (checkIfAccessTokenOkInResponse(data) === false) {
            window.location = '/login';
            return false;
          }
        } else {
          data = false;
        }
      } else {
        data = adsets;
      }

      if (data) {
        data = Object.values(data).map((entry) => ({ ...entry, insightsLoaded: false }));

        localStorage.setItem(`adset-${id}-data-${adAccount}`, JSON.stringify(data));
        localStorage.setItem(`adset-${id}-data-${adAccount}-date`, currentDate);
      }
    } else {
      data = JSON.parse(LCData);
    }
  } catch (error) {
    console.log(error);
  }

  return data;
};

const loadAdsetInsights = async (adAccount, id, from, to, abortSignal) => {
  let data = [];

  try {
    const daterange = `${from}-${to}`;
    const LCData = localStorage.getItem(`adset-${id}-insights-${daterange}-${adAccount}`);
    const LCDataDate = localStorage.getItem(`adset-${id}-insights-${daterange}-${adAccount}-date`);

    const currentDate = new Date().getTime();
    if (LCData === null || (currentDate - LCDataDate) > 300000) {
      if (!DEVELOP_MODE) {
        data = await fetch(`${API_DOMAIN}/v${API_VERSION}/adset/${id}/${daterange}?access-token=${ACCESS_TOKEN}&at=${getCookie('fbat')}&adaccount=${adAccount}`, { signal: abortSignal });

        if (data.ok) {
          data = await data.json();

          if (checkIfAccessTokenOkInResponse(data) === false) {
            window.location = '/login';
            return false;
          }
        } else {
          data = false;
        }
      } else {
        data = adsetInsights;
      }

      if (data) {
        data.insightDate = daterange;

        localStorage.setItem(`adset-${id}-insights-${daterange}-${adAccount}`, JSON.stringify(data));
        localStorage.setItem(`adset-${id}-insights-${daterange}-${adAccount}-date`, currentDate);
      }
    } else {
      data = JSON.parse(LCData);
    }
  } catch (error) {
    console.log(error);

    return false;
  }

  return data;
};

const changeLocalValue = (data, id, name, value, cid) => {
  Object.entries(data).forEach((entry) => {
    if (entry[1].id === id) {
      entry[1][name] = value;
    }
  });

  localStorage.setItem(`adset-${cid}-data`, JSON.stringify(data));

  return data;
};

const insertAdsetInsights = (data, id, insights, cid) => {
  data.forEach((entry, index) => {
    if (entry.id === id) {
      data[index] = {
        ...entry,
        ...insights
      };
    }
  });

  data = changeLocalValue(data, id, 'insightsLoaded', true, cid);

  return data;
};

const changeAdsetState = async (adAccount, data, id, cstate, cid) => {
  if (!DEVELOP_MODE) {
    const formData = new FormData();
    formData.append('status', cstate);

    const requestOptions = {
      method: 'POST',
      body: formData
    };
    const url = `${API_DOMAIN}/v${API_VERSION}/adset/${id}?access-token=${ACCESS_TOKEN}&at=${getCookie('fbat')}&adaccount=${adAccount}`;
    const response = await fetch(url, requestOptions);

    if (!response.ok) return false;
  }

  return changeLocalValue(data, id, 'status', cstate, cid);
};

const unsetInsights = (data) => {
  Object.entries(data).forEach((entry) => {
    entry[1].insightsLoaded = false;
    entry[1].insightsLoading = false;
  });

  return data;
};

const AdsetReducer = (state, action) => {
  switch (action.type) {
    case 'ADSET_LOADING':
      return {
        ...state,
        loaded: false,
        allLoaded: false,
        dataAbort: action.payload.abort
      };
    case 'SET_ADSET_DATA':
      return {
        ...state,
        loaded: true,
        allLoaded: true,
        initialized: true,
        data: action.payload.data
      };
    case 'ADSET_INSIGHTS_LOADING': {
      const processedData = changeLocalValue(state.data, action.payload.id, 'insightsLoading', true, action.payload.cid);

      return {
        ...state,
        data: processedData
      };
    }
    case 'SET_ADSET_INSIGHTS': {
      const processedData = insertAdsetInsights(state.data, action.payload.id, action.payload.insights, action.payload.cid);

      return {
        ...state,
        insightsLoaded: true,
        data: processedData
      };
    }
    case 'SET_ADSET_STATE':
      return {
        ...state,
        data: action.payload.processedData
      };
    case 'UNLOAD_INSIGHTS': {
      const processedData = unsetInsights(state.data);

      return {
        ...state,
        data: processedData
      };
    }
    default:
      return state;
  }
};

export {
  AdsetReducer, loadAdsetData, loadAdsetInsights, changeAdsetState
};
