import moment from 'moment';
import combineReducers from 'react-combine-reducers';
import { AdaccountReducer } from './adaccountReducer';
import { AdsetReducer } from './adsetReducer';
import { CampaignReducer } from './campaignReducer';
import DaterangeReducer from './daterangeReducer';
import NotificationReducer from './notificationReducer';

let selectedPeriod = localStorage.getItem('selected-period');
let fromDate;
let toDate;

if (selectedPeriod === null) {
  toDate = moment().format('YYYY-MM-DD');
  fromDate = moment().subtract(10, 'days').format('YYYY-MM-DD');

  localStorage.setItem('selected-period', JSON.stringify({ from: fromDate, to: toDate }));
} else {
  selectedPeriod = JSON.parse(selectedPeriod);
  toDate = selectedPeriod.to;
  fromDate = selectedPeriod.from;
}

const [combinedReducers, initialState] = combineReducers({
  daterange: [DaterangeReducer, { from: fromDate, to: toDate }],
  notification: [NotificationReducer, {
    type: 'success',
    duration: 4000,
    message: '',
    open: false
  }],
  adaccount: [AdaccountReducer, {
    loadedInfo: false,
    loadedInsights: false,
    loadedList: false,
    selectedId: 0,
    info: {},
    list: [],
    insights: {}
  }],
  campaign: [CampaignReducer, { loaded: false, data: {} }],
  adset: [AdsetReducer, { loaded: false, data: {} }]
});

export { initialState, combinedReducers };
