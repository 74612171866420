import { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Toolbar,
  Hidden,
  IconButton,
  Typography,
  Grid,
  useMediaQuery
} from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { green, red } from '@mui/material/colors';
import MenuIcon from '@mui/icons-material/Menu';
import { useAppState } from 'src/contexts/AppState';
import MyDateRangePicker from './dashboard/MyDateRangePicker';
import Facebook from '../icons/Facebook';

const DashboardNavbar = ({ onMobileNavOpen, ...rest }) => {
  const [state] = useAppState();
  const isSmallScreen = useMediaQuery('(min-width: 800px)');

  useEffect(() => {

  }, [state.adaccount.info]);

  const getTotals = () => {
    const adAccounts = state.adaccount.list;
    const daterange = `${state.daterange.from}-${state.daterange.to}`;
    const totals = {
      totalSpend: 0,
      totalEarnings: 0,
      totalPurchases: 0,
      prevTotalSpend: 0,
      prevTotalEarnings: 0,
      prevTotalPurchases: 0,
      spendChangeDirection: null,
      earningsChangeDirection: null,
      purchasesChangeDirection: null,
    };

    if (adAccounts.length) {
      for (let i = 0; i < adAccounts.length; i++) {
        const insights = JSON.parse(localStorage.getItem(`adaccount-insights-${daterange}-${adAccounts[i].id}`));
        if (insights) {
          totals.totalSpend += parseFloat(insights.total_spent);
          totals.totalEarnings += parseFloat(insights.purchaseValue);
          totals.totalPurchases += parseFloat(insights.purchaseCount);
          totals.prevTotalSpend += parseFloat(insights.total_spent_previous);
          totals.prevTotalEarnings += parseFloat(insights.purchaseValue_previous);
          totals.prevTotalPurchases += parseFloat(insights.purchaseCount_previous);
        }
      }

      totals.totalSpend = Math.round((totals.totalSpend + Number.EPSILON) * 100) / 100;
      totals.totalEarnings = Math.round((totals.totalEarnings + Number.EPSILON) * 100) / 100;

      if (totals.totalSpend > totals.prevTotalSpend) {
        totals.spendChangeDirection = <ArrowUpward sx={{ color: green[900], width: '0.7em', marginLeft: '5px' }} />;
      } else if (totals.totalSpend < totals.prevTotalSpend) {
        totals.spendChangeDirection = <ArrowDownward sx={{ color: red[900], width: '0.7em', marginLeft: '5px' }} />;
      }

      if (totals.totalEarnings > totals.prevTotalEarnings) {
        totals.earningsChangeDirection = <ArrowUpward sx={{ color: green[900], width: '0.7em', marginLeft: '5px' }} />;
      } else if (totals.totalEarnings < totals.prevTotalEarnings) {
        totals.earningsChangeDirection = <ArrowDownward sx={{ color: red[900], width: '0.7em', marginLeft: '5px' }} />;
      }

      if (totals.totalPurchases > totals.prevTotalPurchases) {
        totals.purchasesChangeDirection = <ArrowUpward sx={{ color: green[900], width: '0.7em', marginLeft: '5px' }} />;
      } else if (totals.totalPurchases < totals.prevTotalPurchases) {
        totals.purchasesChangeDirection = <ArrowDownward sx={{ color: red[900], width: '0.7em', marginLeft: '5px' }} />;
      }
    }

    return totals;
  };

  const totals = getTotals();

  return (
    <AppBar
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <RouterLink to="/">
          <Facebook style={{ color: '#fff', fontSize: '35px' }} />
        </RouterLink>
        { isSmallScreen && (
          <Box sx={{ marginLeft: '20px' }}>
            <Grid
              container
              gap={1}
              sx={{
                background: 'white',
                padding: '5px 20px',
                borderRadius: '10px',
                color: 'black'
              }}
            >
              <Grid item>
                <Typography variant="body2" sx={{ fontWeight: 600 }}>
                  {totals.totalSpend}
                  &nbsp;lei
                  {totals.spendChangeDirection}
                </Typography>
              </Grid>
              |
              <Grid item>
                <Typography variant="body2" sx={{ fontWeight: 600 }}>
                  {totals.totalEarnings}
                  &nbsp;lei
                  {totals.earningsChangeDirection}
                </Typography>
              </Grid>
              |
              <Grid item>
                <Typography variant="body2">
                  {totals.totalPurchases}
                  {totals.purchasesChangeDirection}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}
        <Box sx={{ flexGrow: 1 }}>
          <MyDateRangePicker />
        </Box>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
      { !isSmallScreen && (
        <Toolbar sx={{ justifyContent: 'center' }}>
          <Box>
            <Grid
              container
              gap={1}
              sx={{
                background: 'white',
                padding: '5px 20px',
                borderRadius: '10px',
                marginTop: '-10px',
                color: 'black'
              }}
            >
              <Grid item>
                <Typography variant="body2" sx={{ fontWeight: 600 }}>
                  {totals.totalSpend}
                  &nbsp;lei
                  {totals.spendChangeDirection}
                </Typography>
              </Grid>
              |
              <Grid item>
                <Typography variant="body2" sx={{ fontWeight: 600 }}>
                  {totals.totalEarnings}
                  &nbsp;lei
                  {totals.earningsChangeDirection}
                </Typography>
              </Grid>
              |
              <Grid item>
                <Typography variant="body2">
                  {totals.totalPurchases}
                  {totals.purchasesChangeDirection}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Toolbar>
      )}
    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func
};

export default DashboardNavbar;
