import {
  Avatar,
  Card,
  CardContent,
  LinearProgress,
  Box,
  Grid,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { indigo } from '@mui/material/colors';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import ReactPlaceholder from 'react-placeholder/lib';
import 'react-placeholder/lib/reactPlaceholder.css';
import { useAppState } from '../../contexts/AppState';

const ActiveAds = (props) => {
  const [state] = useAppState();

  const percentage = (state.adaccount.info.ad_volume * 100) / 250;

  const useCardStyles = makeStyles((theme) => ({
    root: {
      [theme.breakpoints.between('lg', 'xl')]: {
        position: 'relative',
        marginTop: '10px',
        paddingTop: '5px',
        overflow: 'visible !important',
      }
    }
  }));

  const cardClasses = useCardStyles();

  const useAvatarStyles = makeStyles((theme) => ({
    root: {
      [theme.breakpoints.between('lg', 'xl')]: {
        position: 'absolute !important',
        top: '-10px',
        right: '-10px'
      }
    }
  }));

  const avatarClasses = useAvatarStyles();

  return (
    <Card {...props} className={cardClasses.root}>
      <CardContent>
        <Grid
          container
          spacing={3}
          sx={{ justifyContent: 'space-between' }}
          flexWrap="nowrap"
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              RECLAME ACTIVE
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              <ReactPlaceholder showLoadingAnimation type="text" ready={state.adaccount.infoLoaded} rows={1} color="#e0e0e0">
                <Box>
                  {`${state.adaccount.info.ad_volume} / 250`}
                </Box>
              </ReactPlaceholder>
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: indigo[600],
                height: 56,
                width: 56
              }}
              className={avatarClasses.root}
            >
              <PermMediaIcon />
            </Avatar>
          </Grid>
        </Grid>
        <Box sx={{ pt: 3 }}>
          <ReactPlaceholder showLoadingAnimation type="text" ready={state.adaccount.infoLoaded} rows={1} color="#e0e0e0">
            <LinearProgress
              value={percentage}
              variant="determinate"
              color="secondary"
            />
          </ReactPlaceholder>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ActiveAds;
