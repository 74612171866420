import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@mui/material';
import CampaignListResults from 'src/components/campaigns/CampaignListResults';
import CampaignListToolbar from 'src/components/campaigns/CampaignListToolbar';
import { useAppState } from 'src/contexts/AppState';
import {
  loadCampaignData,
  loadCampaignInsights,
  changeCampaignState,
  changeCampaignFavorite
} from 'src/reducers/campaignReducer';

const CampaignList = () => {
  const [state, dispatch] = useAppState();
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaignIds, setSelectedCampaignIds] = useState([]);
  const [searched, setSearched] = useState('');

  const requestSearch = (searchedVal) => {
    setSearched(searchedVal);
    if (state.campaign.data.length) {
      const filteredRows = state.campaign.data.filter((row) => row.name.toLowerCase().includes(searchedVal.toLowerCase()));
      setCampaigns(filteredRows);
    }
  };

  const cancelSearch = () => {
    setSearched('');
    requestSearch('');
  };

  useEffect(async () => {
    if (!state.campaign.loaded) {
      if (typeof state.campaign.dataAbort !== 'undefined') state.campaign.dataAbort.abort();

      const onlyFavorites = true;
      const actionType = 'SET_FAVORITES_DATA';

      const abortC = new AbortController();
      const { signal } = abortC;
      dispatch({ type: 'CAMPAIGN_LOADING', payload: { abort: abortC } });

      const CData = await loadCampaignData(state.adaccount.selectedId, signal, onlyFavorites);
      if (CData) {
        dispatch({
          type: actionType,
          payload: {
            data: CData
          }
        });
      } else {
        dispatch({
          type: 'SHOW_NOTIFICATION',
          payload: {
            type: 'error',
            message: 'Nu sa putut încărca informațille despre campanii',
          }
        });
      }
    }
  }, [state.adaccount.selectedId]);

  useEffect(() => {
    dispatch({ type: 'UNLOAD_INSIGHTS' });
  }, [state.daterange.from, state.daterange.to, state.adaccount.selectedId]);

  const handleLoadInfo = async (id) => {
    if (typeof state.campaign.insightsAbort !== 'undefined') state.campaign.insightsAbort.abort();

    const abortC = new AbortController();
    const { signal } = abortC;

    dispatch({
      type: 'CAMPAIGN_INSIGHTS_LOADING',
      payload: {
        id,
        abort: abortC
      }
    });
    const CInsights = await loadCampaignInsights(state.adaccount.selectedId, id, state.daterange.from, state.daterange.to, signal);
    if (CInsights) {
      dispatch({
        type: 'SET_CAMPAIGN_INSIGHTS',
        payload: {
          id,
          insights: CInsights
        }
      });
    } else {
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: {
          type: 'error',
          message: 'Nu sau putut încărca statisticile campaniilor',
        }
      });
    }
  };

  const handleStateChange = async (cid, newState) => {
    const processedData = await changeCampaignState(state.adaccount.selectedId, state.campaign.data, cid, newState);

    if (processedData) {
      dispatch({
        type: 'SET_CAMPAIGN_STATE',
        payload: {
          processedData
        }
      });
    } else {
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: {
          type: 'error',
          message: 'Nu sa putut seta starea campaniei',
        }
      });
    }
  };

  const handleFavorite = async (cid, fstate) => {
    const processedData = await changeCampaignFavorite(state.adaccount.selectedId, state.campaign.data, cid, fstate);

    if (processedData) {
      dispatch({
        type: 'SET_CAMPAIGN_FAVORITE',
        payload: {
          processedData
        }
      });
    } else {
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: {
          type: 'error',
          message: 'Nu sa putut seta ca favorit',
        }
      });
    }
  };

  useEffect(() => {
    setCampaigns(Object.values(state.campaign.data));
    requestSearch(searched);
  }, [state.campaign, state.adaccount.selectedId]);

  return (
    <>
      <Helmet>
        <title>Campanii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <h4>Campanii</h4>
          <CampaignListToolbar selectedCampaignIds={selectedCampaignIds} requestSearch={requestSearch} cancelSearch={cancelSearch} searched={searched} handleLoadInfo={handleLoadInfo} handleStateChange={handleStateChange} />
          <Box sx={{ pt: 3 }}>
            <CampaignListResults selectedCampaignIds={selectedCampaignIds} setSelectedCampaignIds={setSelectedCampaignIds} handleLoadInfo={handleLoadInfo} handleStateChange={handleStateChange} handleFavorite={handleFavorite} campaigns={campaigns} loaded={state.campaign.loaded.toString()} />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default CampaignList;
