import { Grid } from '@mui/material';
import { Calendar } from 'react-feather';
import moment from 'moment';
import { useAppState } from 'src/contexts/AppState';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { useRef, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'moment/min/locales';

const dispatchDateChange = async (dispatch, start, end) => {
  dispatch({
    type: 'SET_DATE_RANGE',
    payload: {
      from: start,
      to: end
    }
  });

  localStorage.setItem('selected-period', JSON.stringify({ from: start, to: end }));
};

const MyDateRangePicker = () => {
  const [state, dispatch] = useAppState();

  const startDatePicker = moment(state.daterange.from).format('MMM DD YYYY');
  const endDatePicker = moment(state.daterange.to).format('MMM DD YYYY');

  const initialOpens = (window.innerWidth > 1280) ? 'left' : 'center';

  const inputRef = useRef(null);
  const keyRef = useRef(Date.now());

  const handleClick = () => {
    inputRef.current.ref.click();
  };

  const [pickerOpens, setPickerOpens] = useState(initialOpens);

  const handleResize = () => {
    const wwidth = window.innerWidth;
    const picker = document.getElementsByClassName('daterangepicker')[0];

    if (wwidth < 1280) {
      keyRef.current = Date.now();
      setPickerOpens('center');
    } else {
      keyRef.current = Date.now();
      setPickerOpens('left');
    }

    if (wwidth < 600) {
      setTimeout(() => {
        const pwidth = picker.offsetWidth;
        picker.style.top = '55px';
        picker.style.left = `${(wwidth - pwidth) / 2}px`;
        picker.style.right = 'auto';
      }, 10);
    } else {
      picker.style.removeProperty('transform');
    }
  };

  const handleCalendarShow = (event, picker) => {
    const wwidth = window.innerWidth;
    if (wwidth < 600) {
      const pcontainer = picker.container[0];
      const pwidth = pcontainer.offsetWidth;
      pcontainer.style.top = '55px';
      pcontainer.style.left = `${(wwidth - pwidth) / 2}px`;
      pcontainer.style.right = 'auto';
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  });

  return (
    <Grid container spacing={1} alignItems="center" justifyContent={{ lg: 'flex-end', xs: 'center' }}>
      <Grid item>
        <Calendar onClick={handleClick} />
      </Grid>
      <Grid item display={{ sm: 'block', xs: 'none' }}>
        <DateRangePicker
          key={keyRef.current}
          initialSettings={
            {
              startDate: startDatePicker,
              endDate: endDatePicker,
              alwaysShowCalendars: true,
              opens: pickerOpens,
              locale: {
                format: 'MMM DD YYYY',
                customRangeLabel: 'Custom',
                monthNames: [
                  'Ianuarie',
                  'Februarie',
                  'Martie',
                  'Aprilie',
                  'Mai',
                  'Iunie',
                  'Iulie',
                  'August',
                  'Septembrie',
                  'Octombrie',
                  'Noiembrie',
                  'Decembrie'
                ],
                applyLabel: 'Aplică',
                cancelLabel: 'Renunță',
                fromLabel: 'De la',
                toLabel: 'Până la',
                daysOfWeek: [
                  'Du',
                  'Lu',
                  'Ma',
                  'Mi',
                  'Jo',
                  'Vi',
                  'Sa'
                ],
                firstDay: 1
              },
              ranges: {
                Astăzi: [moment().toDate(), moment().toDate()],
                Ieri: [
                  moment().subtract(1, 'days').toDate(),
                  moment().subtract(1, 'days').toDate(),
                ],
                'Ultimele 7 zile': [
                  moment().subtract(6, 'days').toDate(),
                  moment().toDate(),
                ],
                'Ultimele 30 de zile': [
                  moment().subtract(29, 'days').toDate(),
                  moment().toDate(),
                ],
                'Luna curentă': [
                  moment().startOf('month').toDate(),
                  moment().endOf('month').toDate(),
                ],
                'Ultima lună': [
                  moment().subtract(1, 'month').startOf('month').toDate(),
                  moment().subtract(1, 'month').endOf('month').toDate(),
                ],
              }
            }
          }
          onApply={(event, picker) => dispatchDateChange(dispatch, picker.startDate.format('YYYY-MM-DD'), picker.endDate.format('YYYY-MM-DD'))}
          onShow={handleCalendarShow}
          ref={inputRef}
        >
          <input type="text" className="form-control" style={{ width: 190, fontSize: '0.8rem', textAlign: 'center' }} />
        </DateRangePicker>
      </Grid>
    </Grid>
  );
};

export default MyDateRangePicker;
