import {
  Avatar,
  Divider,
  Paper,
  Typography,
  Box
} from '@mui/material';
import FacebookLogin from 'react-facebook-login';
import { Link as RouterLink } from 'react-router-dom';
import { getUserInfo } from 'src/utils/user';
import { setCookie } from 'src/utils/cookie';
import { Facebook } from '@mui/icons-material';
import { getLongLivedAccessToken } from 'src/reducers/adaccountReducer';

const Login = () => {
  const responseFacebook = async (response) => {
    const accessToken = await getLongLivedAccessToken(response.accessToken);

    let expirationDate = new Date();
    expirationDate = new Date(expirationDate.getTime() + (accessToken.expires_in * 1000));
    expirationDate = expirationDate.toUTCString();

    setCookie('fbat', accessToken.access_token, expirationDate);
    setCookie('fbname', response.name, expirationDate);

    window.location = '/';
  };

  const user = getUserInfo();

  return (
    <Paper
      sx={{
        maxWidth: '400px',
        width: '90%',
        margin: '0px auto',
        position: 'relative',
        top: '50%',
        transform: 'translateY(-50%)',
        p: 3,
        textAlign: 'center'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}
      >
        <Avatar
          component={RouterLink}
          src={user.avatar}
          sx={{
            cursor: 'pointer',
            width: 64,
            height: 64
          }}
          to="/app/account"
        />
        <Typography
          color="textPrimary"
          variant="h5"
        >
          {user.name}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {user.jobTitle}
        </Typography>
      </Box>
      <Divider />
      <br />
      <FacebookLogin
        appId="393075329776651"
        fields="name"
        callback={responseFacebook}
        icon={<Facebook sx={{ mr: 2 }} />}
        cssClass="kep-login-facebook"
        textButton="Logheaza-te cu Facebook"
        redirectUri="https://fb.sisicosmetice.ro/login"
      />
    </Paper>
  );
};

export default Login;
