import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Switch,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  Tabs,
  Tab,
  IconButton
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import { Star, FormatListBulleted } from '@mui/icons-material';
import { useAppState } from 'src/contexts/AppState';
import ReactPlaceholder from 'react-placeholder/lib';
import { yellow } from '@mui/material/colors';
import TablePlaceholder from './TablePlaceholder';
import TabPanel from '../TabPanel';
import DownloadCampaignButton from './DownloadCampaignButton';
import { loadCampaignData } from '../../reducers/campaignReducer';

const CampaignListResults = ({
  selectedCampaignIds,
  setSelectedCampaignIds,
  campaigns,
  handleLoadInfo,
  handleStateChange,
  handleFavorite,
  ...rest
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }));

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [start, setStart] = useState(0);
  const [favorites, setFavorites] = useState(campaigns.filter((campaign) => campaign.favorite === true));
  const [state, dispatch] = useAppState();
  const [tab, setTab] = useState(0);
  const [totals, setTotals] = useState([0, 0, 0]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('stateCol');
  const classes = useStyles();

  const headCells = [
    { id: 'name', numeric: false, label: 'Nume' },
    { id: 'favorite', numeric: false, label: ' ' },
    { id: 'status', numeric: false, label: 'Stare' },
    { id: 'purchases', numeric: false, label: 'Rezultate' },
    { id: 'spent', numeric: true, label: 'Suma cheltuită' },
    { id: 'purchaseValue', numeric: true, label: 'Vânzări' }
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const descendingComparator = (a, b, orderingBy) => {
    if (b[orderingBy] < a[orderingBy]) {
      return -1;
    }
    if (b[orderingBy] > a[orderingBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (ordering, orderingBy) => ((ordering === 'desc') ? (a, b) => descendingComparator(a, b, orderingBy) : (a, b) => -descendingComparator(a, b, orderingBy));

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const ordering = comparator(a[0], b[0]);
      if (ordering !== 0) return ordering;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const handleSelectAll = (event) => {
    let newSelectedCampaignIds;
    const campaignsArray = (tab === 0) ? favorites : campaigns;

    if (event.target.checked) {
      newSelectedCampaignIds = campaignsArray.map((campaign) => campaign.id);
    } else {
      newSelectedCampaignIds = [];
    }

    setSelectedCampaignIds(newSelectedCampaignIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCampaignIds.indexOf(id);
    let newSelectedCampaignIds = [];

    if (selectedIndex === -1) {
      newSelectedCampaignIds = newSelectedCampaignIds.concat(selectedCampaignIds, id);
    } else if (selectedIndex === 0) {
      newSelectedCampaignIds = newSelectedCampaignIds.concat(selectedCampaignIds.slice(1));
    } else if (selectedIndex === selectedCampaignIds.length - 1) {
      newSelectedCampaignIds = newSelectedCampaignIds.concat(selectedCampaignIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedCampaignIds = newSelectedCampaignIds.concat(
        selectedCampaignIds.slice(0, selectedIndex),
        selectedCampaignIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCampaignIds(newSelectedCampaignIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setStart(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    setStart(newPage * limit);
  };

  const handleTabChange = async (event, newValue) => {
    handlePageChange({}, 0);
    setTab(newValue);

    if (!state.campaign.allLoaded && state.campaign.loaded) {
      if (typeof state.campaign.dataAbort !== 'undefined') state.campaign.dataAbort.abort();

      const abortC = new AbortController();
      const { signal } = abortC;
      dispatch({ type: 'CAMPAIGN_LOADING', payload: { abort: abortC } });

      const CData = await loadCampaignData(state.adaccount.selectedId, signal);
      if (CData) {
        dispatch({
          type: 'SET_CAMPAIGN_DATA',
          payload: {
            data: CData
          }
        });
      } else {
        dispatch({
          type: 'SHOW_NOTIFICATION',
          payload: {
            type: 'error',
            message: 'Nu sa putut încărca informațille despre campanii',
          }
        });
      }
    }
  };

  useEffect(() => {
    setFavorites(campaigns.filter((campaign) => campaign.favorite === true));
  }, [campaigns, state.adaccount.selectedId]);

  useEffect(() => {
    setPage(0);
    setStart(0);
  }, [campaigns.length, state.adaccount.selectedId]);

  useEffect(() => {
    let [totalResult, totalSpent, totalRevenue] = [0, 0, 0];

    favorites.forEach((favorite) => {
      totalResult += parseInt(favorite.purchases, 10) || 0;
      totalSpent += parseFloat(favorite.spent) || 0;
      totalRevenue += parseFloat(favorite.purchaseValue) || 0;
    });

    setTotals([totalResult, totalSpent.toFixed(2), totalRevenue.toFixed(2)]);
  }, [favorites, state.adaccount.selectedId]);

  const favoriteTheme = createTheme({
    palette: {
      primary: {
        main: yellow[700],
      }
    },
  });

  return (
    <>
      <Paper>
        <Tabs
          value={tab}
          indicatorColor="primary"
          variant="fullWidth"
          onChange={handleTabChange}
        >
          <Tab icon={<Star />} label="Favorite" />
          <Tab icon={<FormatListBulleted />} label="Toate" />
        </Tabs>
      </Paper>
      <TabPanel value={tab} index={0}>
        <Card {...rest}>
          <PerfectScrollbar>
            <Box sx={{ minWidth: 1050 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedCampaignIds.length >= favorites.length}
                        color="primary"
                        indeterminate={
                          selectedCampaignIds.length > 0
                          && selectedCampaignIds.length < favorites.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    <TableCell>
                      &nbsp;
                    </TableCell>
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                      >
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={(event) => handleRequestSort(event, headCell.id)}
                        >
                          {headCell.label}
                          {orderBy === headCell.id ? (
                            <span className={classes.visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </span>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <ReactPlaceholder ready={state.campaign.loaded} customPlaceholder={<TablePlaceholder rowCount={10} columnCount={8} />}>
                    {stableSort(favorites, getComparator(order, orderBy)).map((favorite) => {
                      let campaignActive = false;
                      if (state.campaign.loaded) {
                        campaignActive = (favorite.status === 'ACTIVE');
                      }

                      return (
                        <TableRow
                          hover
                          key={favorite.id}
                          selected={selectedCampaignIds.indexOf(favorite.id) !== -1}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={selectedCampaignIds.indexOf(favorite.id) !== -1}
                              onChange={(event) => handleSelectOne(event, favorite.id)}
                              value="true"
                            />
                          </TableCell>
                          <TableCell>
                            <Box>
                              <Switch color="secondary" checked={campaignActive} onChange={(event) => handleStateChange(favorite.id, (event.target.checked === true) ? 'ACTIVE' : 'PAUSED')} name="campaignStatus[]" />
                            </Box>
                          </TableCell>
                          <TableCell>
                            <RouterLink to={`${favorite.id}`}>
                              {(state.campaign.loaded) ? favorite.name : ''}
                            </RouterLink>
                          </TableCell>
                          <TableCell>
                            <IconButton theme={favoriteTheme} color={(favorite.favorite) ? 'primary' : 'default'} size="small" onClick={() => { handleFavorite(favorite.id, !favorite.favorite); }}>
                              <Star />
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            <Box>{(state.campaign.loaded) ? favorite.status : ''}</Box>
                          </TableCell>
                          {(favorite.insightsLoading || favorite.insightsLoaded) ? (
                            <>
                              <TableCell align="center">
                                <ReactPlaceholder showLoadingAnimation type="text" ready={favorite.insightsLoaded} rows={1} color="#e0e0e0">
                                  <Box>{(favorite.insightsLoaded) ? favorite.purchases : ''}</Box>
                                </ReactPlaceholder>
                              </TableCell>
                              <TableCell align="right">
                                <ReactPlaceholder showLoadingAnimation type="text" ready={favorite.insightsLoaded} rows={1} color="#e0e0e0">
                                  <Box>{(favorite.insightsLoaded) ? favorite.spent : ''}</Box>
                                </ReactPlaceholder>
                              </TableCell>
                              <TableCell align="right">
                                <ReactPlaceholder showLoadingAnimation type="text" ready={favorite.insightsLoaded} rows={1} color="#e0e0e0">
                                  <Box>{(favorite.insightsLoaded) ? favorite.purchaseValue : ''}</Box>
                                </ReactPlaceholder>
                              </TableCell>
                            </>
                          ) : (
                            <TableCell colSpan="3" align="center">
                              <DownloadCampaignButton campaignId={favorite.id} handleLoadInfo={handleLoadInfo} />
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                    <TableRow>
                      <TableCell colSpan={5}>&nbsp;</TableCell>
                      <TableCell align="center" style={{ fontWeight: 600 }}>{totals[0]}</TableCell>
                      <TableCell align="right" style={{ fontWeight: 600 }}>{totals[1]}</TableCell>
                      <TableCell align="right" style={{ fontWeight: 600 }}>{totals[2]}</TableCell>
                    </TableRow>
                  </ReactPlaceholder>
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
        </Card>
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <Card {...rest}>
          <PerfectScrollbar>
            <Box sx={{ minWidth: 1050 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedCampaignIds.length === campaigns.length}
                        color="primary"
                        indeterminate={
                          selectedCampaignIds.length > 0
                          && selectedCampaignIds.length < campaigns.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    <TableCell>
                      &nbsp;
                    </TableCell>
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                      >
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={(event) => handleRequestSort(event, headCell.id)}
                        >
                          {headCell.label}
                          {orderBy === headCell.id ? (
                            <span className={classes.visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </span>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <ReactPlaceholder ready={state.campaign.loaded} customPlaceholder={<TablePlaceholder rowCount={10} columnCount={8} />}>
                    {stableSort(campaigns, getComparator(order, orderBy)).slice(start, start + limit).map((campaign) => {
                      let campaignActive = false;
                      if (state.campaign.loaded) {
                        campaignActive = (campaign.status === 'ACTIVE');
                      }

                      return (
                        <TableRow
                          hover
                          key={campaign.id}
                          selected={selectedCampaignIds.indexOf(campaign.id) !== -1}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={selectedCampaignIds.indexOf(campaign.id) !== -1}
                              onChange={(event) => handleSelectOne(event, campaign.id)}
                              value="true"
                            />
                          </TableCell>
                          <TableCell>
                            <Box>
                              <Switch color="secondary" checked={campaignActive} onChange={(event) => handleStateChange(campaign.id, (event.target.checked === true) ? 'ACTIVE' : 'PAUSED')} name="campaignStatus[]" />
                            </Box>
                          </TableCell>
                          <TableCell>
                            <RouterLink to={`${campaign.id}`}>
                              {(state.campaign.loaded) ? campaign.name : ''}
                            </RouterLink>
                          </TableCell>
                          <TableCell>
                            <IconButton theme={favoriteTheme} color={(campaign.favorite) ? 'primary' : 'default'} size="small" onClick={() => { handleFavorite(campaign.id, !campaign.favorite); }}>
                              <Star />
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            <Box>{(state.campaign.loaded) ? campaign.status : ''}</Box>
                          </TableCell>
                          {(campaign.insightsLoading || campaign.insightsLoaded) ? (
                            <>
                              <TableCell align="center">
                                <ReactPlaceholder showLoadingAnimation type="text" ready={campaign.insightsLoaded} rows={1} color="#e0e0e0">
                                  <Box>{(campaign.insightsLoaded) ? campaign.purchases : ''}</Box>
                                </ReactPlaceholder>
                              </TableCell>
                              <TableCell align="right">
                                <ReactPlaceholder showLoadingAnimation type="text" ready={campaign.insightsLoaded} rows={1} color="#e0e0e0">
                                  <Box>{(campaign.insightsLoaded) ? campaign.spent : ''}</Box>
                                </ReactPlaceholder>
                              </TableCell>
                              <TableCell align="right">
                                <ReactPlaceholder showLoadingAnimation type="text" ready={campaign.insightsLoaded} rows={1} color="#e0e0e0">
                                  <Box>{(campaign.insightsLoaded) ? campaign.purchaseValue : ''}</Box>
                                </ReactPlaceholder>
                              </TableCell>
                            </>
                          ) : (
                            <TableCell colSpan="3" align="center">
                              <DownloadCampaignButton campaignId={campaign.id} handleLoadInfo={handleLoadInfo} />
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                  </ReactPlaceholder>
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            count={campaigns.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 15, 25]}
          />
        </Card>
      </TabPanel>
    </>
  );
};

CampaignListResults.propTypes = {
  campaigns: PropTypes.array.isRequired,
  selectedCampaignIds: PropTypes.array,
  setSelectedCampaignIds: PropTypes.func,
  handleLoadInfo: PropTypes.func,
  handleStateChange: PropTypes.func,
  handleFavorite: PropTypes.func
};

export default CampaignListResults;
