// https://codezup.com/how-to-combine-multiple-reducers-in-react-hooks-usereducer/
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import GlobalStyles from 'src/components/GlobalStyles';
import theme from 'src/theme';
import routes from 'src/routes';
import { AppStateProvider } from './contexts/AppState';
import { initialState, combinedReducers } from './reducers';

const App = () => {
  const routing = useRoutes(routes);

  return (
    <AppStateProvider reducer={combinedReducers} initialState={initialState}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {routing}
      </ThemeProvider>
    </AppStateProvider>
  );
};

export default App;
