import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  FormControl,
  Hidden,
  InputLabel,
  List,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import {
  BarChart as BarChartIcon,
  DollarSign as DollarSignIcon,
  Activity as ActivityIcon,
  Folder as FolderIcon
} from 'react-feather';
import { getUserInfo } from 'src/utils/user';
import { useAppState } from 'src/contexts/AppState';
import NavItem from './NavItem';

const user = getUserInfo();

const items = [
  {
    href: '/app/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard'
  },
  {
    href: '/app/campaigns',
    icon: FolderIcon,
    title: 'Campanii'
  },
  {
    href: '/app/billing',
    icon: DollarSignIcon,
    title: 'Financiar'
  },
  {
    href: '/app/activity',
    icon: ActivityIcon,
    title: 'Activitate'
  }
];

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();
  const [state, dispatch] = useAppState();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const handleAdAccountChange = (event) => {
    dispatch({ type: 'RESET_ADACCOUNT_INFO' });
    dispatch({ type: 'RESET_CAMPAIGN_DATA' });
    dispatch({ type: 'SET_SELECTED_ADACCOUNT', payload: event.target.value });
  };

  const content = (state.adaccount.list.length > 0) && (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}
      >
        <Avatar
          component={RouterLink}
          src={user.avatar}
          sx={{
            cursor: 'pointer',
            width: 64,
            height: 64
          }}
          to="/app/account"
        />
        <Typography
          color="textPrimary"
          variant="h5"
        >
          {user.name}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {user.jobTitle}
        </Typography>
      </Box>
      <Divider />
      <br />
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <InputLabel id="ad-account-select-label">Ad Account</InputLabel>
        <Select
          labelId="ad-account-select-label"
          id="ad-account-select"
          label="Ad Account"
          value={state.adaccount.selectedId}
          onChange={handleAdAccountChange}
        >
          {state.adaccount.list.map((item) => (
            <MenuItem key={item.id} value={item.id}>{`${item.name} (${item.id})`}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <br />
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

export default DashboardSidebar;
