import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Grid,
  Button
} from '@mui/material';
import { withStyles } from '@mui/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AdsetListResults from 'src/components/campaigns/adsets/AdsetListResults';
import AdsetListToolbar from 'src/components/campaigns/adsets/AdsetListToolbar';
import { useAppState } from 'src/contexts/AppState';
import { loadAdsetData, loadAdsetInsights, changeAdsetState } from 'src/reducers/adsetReducer';
import { API_DOMAIN, API_VERSION, ACCESS_TOKEN } from 'src/config/main.json';

const AdsetList = () => {
  const [state, dispatch] = useAppState();
  const [adsets, setAdsets] = useState([]);
  const [selectedAdsetIds, setSelectedAdsetIds] = useState([]);
  const [searched, setSearched] = useState('');
  const [campaignName, setCampaignName] = useState('Ad Seturi');
  const { id } = useParams();

  const requestSearch = (searchedVal) => {
    setSearched(searchedVal);
    if (state.adset.data.length) {
      const filteredRows = state.adset.data.filter((row) => row.name.toLowerCase().includes(searchedVal.toLowerCase()));
      setAdsets(filteredRows);
    }
  };

  const cancelSearch = () => {
    setSearched('');
    requestSearch('');
  };

  useEffect(async () => {
    if (typeof state.adset.dataAbort !== 'undefined') state.adset.dataAbort.abort();

    const abortC = new AbortController();
    const { signal } = abortC;
    dispatch({ type: 'ADSET_LOADING', payload: { abort: abortC } });

    const CData = await loadAdsetData(state.adaccount.selectedId, id, signal);
    if (CData) {
      dispatch({
        type: 'SET_ADSET_DATA',
        payload: {
          data: CData
        }
      });
    } else {
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: {
          type: 'error',
          message: 'Nu sa putut încărca informațille despre adseturi',
        }
      });
    }
  }, [id]);

  useEffect(async () => {
    let campaignNameTmp = '';
    if (Array.isArray(state.campaign.data)) {
      campaignNameTmp = state.campaign.data.filter((campaign) => campaign.id === id)[0].name;
    }

    if (campaignNameTmp === '') {
      let data = await fetch(`${API_DOMAIN}/v${API_VERSION}/campaigns/search/ids/${id}?access-token=${ACCESS_TOKEN}`);
      data = await data.json();
      campaignNameTmp = data[0].name;
    }

    setCampaignName(campaignNameTmp);
  }, []);

  useEffect(() => {
    dispatch({ type: 'UNLOAD_ADSET_INSIGHTS' });
  }, [state.daterange.from, state.daterange.to]);

  const handleLoadInfo = async (aid) => {
    if (typeof state.adset.insightsAbort !== 'undefined') state.adset.insightsAbort.abort();

    const abortC = new AbortController();
    const { signal } = abortC;

    dispatch({
      type: 'ADSET_INSIGHTS_LOADING',
      payload: {
        id: aid,
        cid: id,
        abort: abortC
      }
    });
    const CInsights = await loadAdsetInsights(state.adaccount.selectedId, aid, state.daterange.from, state.daterange.to, signal);
    if (CInsights) {
      dispatch({
        type: 'SET_ADSET_INSIGHTS',
        payload: {
          id: aid,
          cid: id,
          insights: CInsights
        }
      });
    } else {
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: {
          type: 'error',
          message: 'Nu sau putut încărca statisticile adseturilor',
        }
      });
    }
  };

  const handleStateChange = async (cid, newState) => {
    const processedData = await changeAdsetState(state.adaccount.selectedId, state.adset.data, cid, newState, id);

    if (processedData) {
      dispatch({
        type: 'SET_ADSET_STATE',
        payload: {
          processedData
        }
      });
    } else {
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: {
          type: 'error',
          message: 'Nu sa putut seta starea adsetului',
        }
      });
    }
  };

  useEffect(() => {
    setAdsets(Object.values(state.adset.data));
    requestSearch(searched);
  }, [state.adset]);

  const BackButton = withStyles({
    root: {
      '&:hover': {
        color: 'white !important'
      },
      '&active': {
        color: 'white !important'
      },
      '&focus': {
        color: 'white !important'
      }
    }
  })(Button);

  return (
    <>
      <Helmet>
        <title>{campaignName}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Grid container>
            <Grid item sm={8} xs={12}>
              <h3>Ad Seturi</h3>
              <h8>
                <Link to="/app/campaigns">Campanii</Link>
                &nbsp;&raquo;&nbsp;
                {campaignName}
              </h8>
            </Grid>
            <Grid item sm={4} textAlign="right">
              <BackButton
                component={Link}
                to="/app/campaigns"
                variant="contained"
                color="primary"
                startIcon={<ArrowBackIosIcon />}
              >
                înapoi
              </BackButton>
            </Grid>
          </Grid>
          <AdsetListToolbar selectedAdsetIds={selectedAdsetIds} requestSearch={requestSearch} cancelSearch={cancelSearch} searched={searched} handleLoadInfo={handleLoadInfo} handleStateChange={handleStateChange} />
          <Box sx={{ pt: 3 }}>
            <AdsetListResults cid={id} selectedAdsetIds={selectedAdsetIds} setSelectedAdsetIds={setSelectedAdsetIds} handleLoadInfo={handleLoadInfo} handleStateChange={handleStateChange} adsets={adsets} loaded={state.adset.loaded.toString()} />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default AdsetList;
