import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Backdrop, CircularProgress, styled } from '@mui/material';
import GlobalNotification from 'src/components/utils/GlobalNotification';
import { useAppState } from 'src/contexts/AppState';
import { listAdAccounts, loadAdAccountInfo, loadAdAccountInsights } from 'src/reducers/adaccountReducer';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';

const DashboardLayoutRoot = styled('div')(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  })
);

const DashboardLayoutWrapper = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    },
    '@media (max-width:800px)': {
      paddingTop: 125
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 105
    }
  })
);

const DashboardLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const DashboardLayoutContent = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto'
});

const DashboardLayout = () => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [state, dispatch] = useAppState();

  useEffect(async () => {
    if (typeof state.adaccount.listAbort !== 'undefined') state.adaccount.listAbort.abort();

    const abortC = new AbortController();
    const { signal } = abortC;
    dispatch({ type: 'LOADING_LIST', payload: { abort: abortC } });

    const CData = await listAdAccounts(signal);
    if (CData) {
      // Reset previously saved data
      dispatch({ type: 'RESET_ADACCOUNT_INFO' });
      dispatch({ type: 'RESET_CAMPAIGN_DATA' });
      // Save the ad account list
      dispatch({
        type: 'SET_LIST',
        payload: {
          data: CData
        }
      });

      // Load add accounts info to local storage for totalization
      /* eslint-disable no-await-in-loop */
      if (CData.length) {
        for (let i = 0; i < CData.length; i++) {
          await loadAdAccountInfo(CData[i].id);
          await loadAdAccountInsights(CData[i].id, state.daterange.from, state.daterange.to);
        }
      }
      /* eslint-enable no-await-in-loop */

      if (!state.adaccount.selectedId) {
        // Save the selected ad account ID
        dispatch({ type: 'SET_SELECTED_ADACCOUNT', payload: CData[0].id });
      } else {
        dispatch({ type: 'SET_SELECTED_ADACCOUNT', payload: state.adaccount.selectedId });
      }
    } else {
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: {
          type: 'error',
          message: 'Nu sa putut încărca informațille despre conturile de reclame',
        }
      });
    }
  }, [state.daterange.from, state.daterange.to]);

  if (state.adaccount.list.length > 0 && state.adaccount.selectedId) {
    return (
      <DashboardLayoutRoot>
        <DashboardNavbar onMobileNavOpen={() => setMobileNavOpen(true)} />
        <DashboardSidebar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
        />
        <DashboardLayoutWrapper>
          <DashboardLayoutContainer>
            <DashboardLayoutContent>
              <Outlet />
              <GlobalNotification />
            </DashboardLayoutContent>
          </DashboardLayoutContainer>
        </DashboardLayoutWrapper>
      </DashboardLayoutRoot>
    );
  }

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default DashboardLayout;
