import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ReactPlaceholder from 'react-placeholder/lib';
import { useEffect } from 'react';
import { useAppState } from 'src/contexts/AppState';
import { loadCampaignData, loadCampaignInsights, changeCampaignState } from 'src/reducers/campaignReducer';
import { Link as RouterLink } from 'react-router-dom';
import DownloadCampaignButton from '../campaigns/DownloadCampaignButton';
import TablePlaceholder from '../campaigns/TablePlaceholder';
import 'react-placeholder/lib/reactPlaceholder.css';

const Campaigns = (props) => {
  const [state, dispatch] = useAppState();
  const campaigns = Object.values(state.campaign.data);

  const handleChange = async (cid, event) => {
    const cstate = (event.target.checked === true) ? 'ACTIVE' : 'PAUSED';
    const processedData = await changeCampaignState(state.adaccount.selectedId, state.campaign.data, cid, cstate);

    if (processedData) {
      dispatch({
        type: 'SET_CAMPAIGN_STATE',
        payload: {
          processedData
        }
      });
    } else {
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: {
          type: 'error',
          message: 'Nu sa putut seta starea campaniei',
        }
      });
    }
  };

  useEffect(async () => {
    if (!state.campaign.loaded) {
      if (typeof state.campaign.dataAbort !== 'undefined') state.campaign.dataAbort.abort();

      const abortC = new AbortController();
      const { signal } = abortC;
      dispatch({ type: 'CAMPAIGN_LOADING', payload: { abort: abortC } });

      const CData = await loadCampaignData(state.adaccount.selectedId, signal);
      if (CData) {
        dispatch({
          type: 'SET_CAMPAIGN_DATA',
          payload: {
            data: CData
          }
        });
      } else {
        dispatch({
          type: 'SHOW_NOTIFICATION',
          payload: {
            type: 'error',
            message: 'Nu sa putut încărca informațille despre campanii',
          }
        });
      }
    }
  }, [state.adaccount.selectedId]);

  useEffect(() => {
    dispatch({ type: 'UNLOAD_INSIGHTS' });
  }, [state.daterange.from, state.daterange.to, state.adaccount.selectedId]);

  const handleLoadInfo = async (id) => {
    if (typeof state.campaign.insightsAbort !== 'undefined') state.campaign.insightsAbort.abort();

    const abortC = new AbortController();
    const { signal } = abortC;

    dispatch({
      type: 'CAMPAIGN_INSIGHTS_LOADING',
      payload: {
        id,
        abort: abortC
      }
    });
    const CInsights = await loadCampaignInsights(state.adaccount.selectedId, id, state.daterange.from, state.daterange.to, signal);
    if (CInsights) {
      dispatch({
        type: 'SET_CAMPAIGN_INSIGHTS',
        payload: {
          id,
          insights: CInsights
        }
      });
    } else {
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: {
          type: 'error',
          message: 'Nu sau putut încărca statisticile campaniilor',
        }
      });
    }
  };

  return (
    <Card {...props}>
      <CardHeader title="Campanii" />
      <Divider />
      <PerfectScrollbar>
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  &nbsp;
                </TableCell>
                <TableCell>
                  Nume
                </TableCell>
                <TableCell>
                  Stare
                </TableCell>
                <TableCell>
                  Rezultate
                </TableCell>
                <TableCell align="right">
                  Suma cheltuită
                </TableCell>
                <TableCell align="right">
                  Vanzari
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <ReactPlaceholder ready={state.campaign.loaded} customPlaceholder={<TablePlaceholder rowCount={6} columnCount={6} />}>
                {campaigns.slice(0, 6).map((campaign) => {
                  let campaignActive = false;
                  if (state.campaign.initialized) {
                    campaignActive = (campaign.status === 'ACTIVE');
                  }

                  return (
                    <TableRow
                      hover
                      key={`campaign-row-${campaign.id}`}
                    >
                      <TableCell>
                        <ReactPlaceholder showLoadingAnimation type="text" ready={state.campaign.loaded} rows={1} color="#e0e0e0">
                          <Box>
                            <Switch color="secondary" checked={campaignActive} onChange={(event) => handleChange(campaign.id, event)} name="campaignStatus[]" />
                          </Box>
                        </ReactPlaceholder>
                      </TableCell>
                      <TableCell>
                        <ReactPlaceholder showLoadingAnimation type="text" ready={state.campaign.loaded} rows={1} color="#e0e0e0">
                          <Box>{(state.campaign.initialized) ? campaign.name : ''}</Box>
                        </ReactPlaceholder>
                      </TableCell>
                      <TableCell>
                        <ReactPlaceholder showLoadingAnimation type="text" ready={state.campaign.loaded} rows={1} color="#e0e0e0">
                          <Box>{(state.campaign.initialized) ? campaign.status : ''}</Box>
                        </ReactPlaceholder>
                      </TableCell>
                      {(state.campaign.loaded && (campaign.insightsLoading || campaign.insightsLoaded)) ? (
                        <>
                          <TableCell align="center">
                            <ReactPlaceholder showLoadingAnimation type="text" ready={campaign.insightsLoaded} rows={1} color="#e0e0e0">
                              <Box>{(campaign.insightsLoaded) ? campaign.purchases : ''}</Box>
                            </ReactPlaceholder>
                          </TableCell>
                          <TableCell align="right">
                            <ReactPlaceholder showLoadingAnimation type="text" ready={campaign.insightsLoaded} rows={1} color="#e0e0e0">
                              <Box>{(campaign.insightsLoaded) ? campaign.spent : ''}</Box>
                            </ReactPlaceholder>
                          </TableCell>
                          <TableCell align="right">
                            <ReactPlaceholder showLoadingAnimation type="text" ready={campaign.insightsLoaded} rows={1} color="#e0e0e0">
                              <Box>{(campaign.insightsLoaded) ? campaign.purchaseValue : ''}</Box>
                            </ReactPlaceholder>
                          </TableCell>
                        </>
                      ) : (
                        <TableCell colSpan="3" align="center">
                          {(state.campaign.loaded) && <DownloadCampaignButton campaignId={campaign.id} handleLoadInfo={handleLoadInfo} />}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </ReactPlaceholder>
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        <Button
          color="primary"
          component={RouterLink}
          to="/app/campaigns"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text"
        >
          Vezi toate
        </Button>
      </Box>
    </Card>
  );
};

export default Campaigns;
