const checkIfAccessTokenOkInResponse = (data) => {
  if (data.error) {
    if (data.code === 190 || data.code === 200 || data.code === 2500) {
      return false;
    }
  }

  return true;
};

const getUserInfo = () => {
  const user = {
    avatar: '/static/images/avatar.jpg',
    jobTitle: 'Facebook Business Manager',
    name: 'SisiCosmetice'
  };

  return user;
};

export { getUserInfo, checkIfAccessTokenOkInResponse };
