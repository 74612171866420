import { Helmet } from 'react-helmet';
import {
  Box,
  Container
} from '@mui/material';
import Budget from 'src/components/dashboard/Budget';
import BudgetChange from 'src/components/finance/BudgetChange';
import { useState, useEffect } from 'react';
import { useAppState } from 'src/contexts/AppState';
import { loadAdAccountInfo } from 'src/reducers/adaccountReducer';
import Notification from 'src/components/utils/Notification';

const SettingsView = () => {
  const [state, dispatch] = useAppState();
  const [showBudgetChange, setShowBudgetChange] = useState(true);
  const [showNotificaition, setShowNotificaition] = useState(false);
  const [notificationType, setNotificationType] = useState('success');
  const [notificationMessage, setNotificationMessage] = useState('');

  const handleShowBudgetChange = () => {
    setShowBudgetChange(false);
  };

  const handleHideBudgetChange = () => {
    setShowBudgetChange(true);
  };

  useEffect(async () => {
    if (!state.adaccount.infoLoaded && state.adaccount.selectedId !== 0) {
      if (typeof state.adaccount.infoAbort !== 'undefined') state.adaccount.infoAbort.abort();

      const abortC = new AbortController();
      const { signal } = abortC;
      dispatch({ type: 'LOADING_INFO', payload: { abort: abortC } });

      const AAInfo = await loadAdAccountInfo(state.adaccount.selectedId, signal);
      if (AAInfo.length !== 0) {
        dispatch({
          type: 'SET_INFO',
          payload: {
            data: AAInfo
          }
        });
      }
    }
  }, [state.adaccount.selectedId]);

  return (
    <>
      <Helmet>
        <title>Financiar | Material Kit</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="xs">
          <Budget forceShowButton showChangeBudget={showBudgetChange} handleShowBudgetChange={handleShowBudgetChange} />
          { !showBudgetChange && <BudgetChange handleHideBudgetChange={handleHideBudgetChange} showNotification={() => setShowNotificaition(true)} setMessage={setNotificationMessage} setNotificationType={setNotificationType} /> }
        </Container>
      </Box>
      <Notification type={notificationType} message={notificationMessage} open={showNotificaition} setOpen={setShowNotificaition} />
    </>
  );
};

export default SettingsView;
