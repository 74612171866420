import { Box } from '@mui/material';
import { node, any } from 'prop-types';

const TabPanel = ({
  children,
  value,
  index,
  ...other
}) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && (
      <Box pt={1}>
        {children}
      </Box>
    )}
  </div>
);

TabPanel.propTypes = {
  children: node,
  index: any.isRequired,
  value: any.isRequired
};

export default TabPanel;
