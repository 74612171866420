import {
  Box,
  Card,
  CardContent,
  Button,
  Fab,
  Grid,
  Hidden
} from '@mui/material';
import SearchBar from 'material-ui-search-bar';
import { array, func, string } from 'prop-types';
import { GetApp, Pause, PlayArrow } from '@mui/icons-material';

const CampaignListToolbar = ({
  selectedCampaignIds,
  searched,
  requestSearch,
  handleLoadInfo,
  handleStateChange,
  cancelSearch
}) => {
  const handleLoadMultipleInfo = () => {
    if (selectedCampaignIds.length) {
      selectedCampaignIds.forEach((element) => {
        handleLoadInfo(element);
      });
    }
  };

  const handlePauseMultiple = () => {
    if (selectedCampaignIds.length) {
      selectedCampaignIds.forEach((element) => {
        handleStateChange(element, 'PAUSED');
      });
    }
  };

  const handleStartMultiple = () => {
    if (selectedCampaignIds.length) {
      selectedCampaignIds.forEach((element) => {
        handleStateChange(element, 'ACTIVE');
      });
    }
  };

  return (
    <Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Grid container spacing={3} alignItems="center">
              <Grid item md={6} xs={12}>
                <SearchBar
                  value={searched}
                  onChange={(searchVal) => requestSearch(searchVal)}
                  onCancelSearch={() => cancelSearch()}
                  placeholder="Cauta..."
                  classes={{
                    searchContainer: 'mySearchContainer',
                    searchIconButton: 'mySearchIcon'
                  }}
                />
              </Grid>
              <Grid display={(selectedCampaignIds.length) ? 'block' : 'none'} item md={6} xs={12} textAlign={{ md: 'right', xs: 'center' }}>
                <Hidden smDown>
                  <Button variant="contained" size="small" onClick={handleStartMultiple} startIcon={<PlayArrow />}>Pornește</Button>
                  <Button variant="contained" size="small" onClick={handlePauseMultiple} color="secondary" startIcon={<Pause />} sx={{ ml: 2 }}>Oprește</Button>
                  <Button variant="contained" size="small" onClick={handleLoadMultipleInfo} startIcon={<GetApp />} sx={{ ml: 2 }}>informații</Button>
                </Hidden>
                <Hidden smUp>
                  <Fab size="medium" color="primary" onClick={handleStartMultiple} aria-label="Pornește"><PlayArrow /></Fab>
                  <Fab size="medium" color="secondary" onClick={handlePauseMultiple} sx={{ ml: 2 }} aria-label="Oprește"><Pause /></Fab>
                  <Fab size="medium" color="primary" onClick={handleLoadMultipleInfo} sx={{ ml: 2 }} aria-label="Informții"><GetApp /></Fab>
                </Hidden>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

CampaignListToolbar.propTypes = {
  selectedCampaignIds: array,
  requestSearch: func,
  cancelSearch: func,
  handleLoadInfo: func,
  handleStateChange: func,
  searched: string
};

export default CampaignListToolbar;
