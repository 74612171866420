import { checkIfAccessTokenOkInResponse } from 'src/utils/user';
import { getCookie } from 'src/utils/cookie';
import info from '../_mocks_/adaccount-info.json';
import insights from '../_mocks_/adaccount-insights.json';
import {
  API_DOMAIN,
  API_VERSION,
  ACCESS_TOKEN,
  DEVELOP_MODE
} from '../config/main.json';

const listAdAccounts = async (abortSignal) => {
  let data = [];

  try {
    const LCData = localStorage.getItem('adaccount-list');
    const LCDataDate = parseInt(
      localStorage.getItem('adaccount-list-date'),
      10
    );

    const currentDate = new Date().getTime();
    if (LCData === null || currentDate - LCDataDate > 300000) {
      if (!DEVELOP_MODE) {
        data = await fetch(
          `${API_DOMAIN}/v${API_VERSION}/adaccount/list?access-token=${ACCESS_TOKEN}&at=${getCookie('fbat')}`,
          { signal: abortSignal }
        );

        if (data.ok) {
          data = await data.json();

          if (checkIfAccessTokenOkInResponse(data) === false) {
            window.location = '/login';
            return false;
          }
        } else {
          data = false;
        }
      } else {
        data = info;
      }

      if (data) {
        localStorage.setItem('adaccount-list', JSON.stringify(data));
        localStorage.setItem('adaccount-list-date', currentDate);
      }
    } else {
      data = JSON.parse(LCData);
    }
  } catch (error) {
    console.log(error);
  }

  return data;
};

const loadAdAccountInfo = async (adAccount, abortSignal) => {
  let data = [];

  try {
    const LCData = localStorage.getItem(`adaccount-info-${adAccount}`);
    const LCDataDate = parseInt(
      localStorage.getItem(`adaccount-info-${adAccount}-date`),
      10
    );

    const currentDate = new Date().getTime();
    if (LCData === null || currentDate - LCDataDate > 300000) {
      if (!DEVELOP_MODE) {
        data = await fetch(
          `${API_DOMAIN}/v${API_VERSION}/adaccount?access-token=${ACCESS_TOKEN}&at=${getCookie('fbat')}&adaccount=${adAccount}`,
          { signal: abortSignal }
        );

        if (data.ok) {
          data = await data.json();

          if (checkIfAccessTokenOkInResponse(data) === false) {
            window.location = '/login';
            return false;
          }
        } else {
          data = false;
        }
      } else {
        data = info;
      }

      if (data) {
        localStorage.setItem(`adaccount-info-${adAccount}`, JSON.stringify(data));
        localStorage.setItem(`adaccount-info-${adAccount}-date`, currentDate);
      }
    } else {
      data = JSON.parse(LCData);
    }
  } catch (error) {
    console.log(error);
  }

  return data;
};

const loadAdAccountInsights = async (adAccount, from, to, abortSignal) => {
  let data = [];

  try {
    const daterange = `${from}-${to}`;
    const LCData = localStorage.getItem(`adaccount-insights-${daterange}-${adAccount}`);
    const LCDataDate = localStorage.getItem(
      `adaccount-insights-${daterange}-${adAccount}-date`
    );

    const currentDate = new Date().getTime();
    if (LCData === null || currentDate - LCDataDate > 300000) {
      if (!DEVELOP_MODE) {
        data = await fetch(
          `${API_DOMAIN}/v${API_VERSION}/adaccount/insights/${daterange}?access-token=${ACCESS_TOKEN}&at=${getCookie('fbat')}&adaccount=${adAccount}`,
          { signal: abortSignal }
        );

        if (data.ok) {
          data = await data.json();

          if (checkIfAccessTokenOkInResponse(data) === false) {
            window.location = '/login';
            return false;
          }
        } else {
          data = false;
        }
      } else {
        data = insights;
      }

      if (data) {
        localStorage.setItem(
          `adaccount-insights-${daterange}-${adAccount}`,
          JSON.stringify(data)
        );
        localStorage.setItem(`adaccount-insights-${daterange}-${adAccount}-date`, currentDate);
      }
    } else {
      data = JSON.parse(LCData);
    }
  } catch (error) {
    console.log(error);
  }

  return data;
};

const getLongLivedAccessToken = async (accessToken) => {
  let data = [];

  try {
    data = await fetch(
      `${API_DOMAIN}/v${API_VERSION}/adaccount/getLongLivedToken?access-token=${ACCESS_TOKEN}&at=${accessToken}`
    );

    if (data.ok) {
      data = await data.json();

      if (checkIfAccessTokenOkInResponse(data) === false) {
        window.location = '/login';
        return false;
      }
    } else {
      data = false;
    }
  } catch (error) {
    console.log(error);
  }

  return data;
};

const resetBudget = async (adAccount, data) => {
  if (!DEVELOP_MODE) {
    const requestOptions = {
      method: 'POST'
    };
    const url = `${API_DOMAIN}/v${API_VERSION}/adaccount/spendCapReset?access-token=${ACCESS_TOKEN}&at=${getCookie('fbat')}&adaccount=${adAccount}`;
    let response = await fetch(url, requestOptions);
    response = await response.json();

    if (!response.success) return false;
  }

  data.amount_spent = 0;

  localStorage.setItem(`adaccount-info-${adAccount}`, JSON.stringify(data));

  return data;
};

const setBudget = async (adAccount, data, amount) => {
  if (!DEVELOP_MODE) {
    const formData = new FormData();
    formData.append('value', amount);

    const requestOptions = {
      method: 'POST',
      body: formData
    };
    const url = `${API_DOMAIN}/v${API_VERSION}/adaccount/spendCapModify?access-token=${ACCESS_TOKEN}&at=${getCookie('fbat')}&adaccount=${adAccount}`;
    let response = await fetch(url, requestOptions);
    response = await response.json();

    if (!response.success) return false;
  }

  data.spend_cap = amount;

  localStorage.setItem(`adaccount-info-${adAccount}`, JSON.stringify(data));

  return data;
};

const AdaccountReducer = (state, action) => {
  switch (action.type) {
    case 'LOADING_INFO':
      return {
        ...state,
        infoLoaded: false,
        infoAbort: action.payload.abort
      };
    case 'LOADING_LIST':
      return {
        ...state,
        loadedList: false,
        listAbort: action.payload.abort,
        selectedId: 0
      };
    case 'LOADING_INSIGHTS':
      return {
        ...state,
        insightsLoaded: false,
        insightsAbort: action.payload.abort
      };
    case 'RESET_ADACCOUNT_INFO':
      return {
        ...state,
        loadedInfo: false,
        loadedInsights: false,
        infoLoaded: false,
        info: {},
        insights: {}
      };
    case 'SET_LIST':
      return {
        ...state,
        loadedList: true,
        list: action.payload.data
      };
    case 'SET_INFO':
      return {
        ...state,
        infoLoaded: true,
        info: action.payload.data
      };
    case 'SET_INSIGHTS':
      return {
        ...state,
        insightsLoaded: true,
        insights: action.payload.data
      };
    case 'SET_SELECTED_ADACCOUNT':
      return {
        ...state,
        selectedId: action.payload
      };
    case 'RESET_BUDGET':
      return {
        ...state,
        info: action.payload.newInfoState
      };
    case 'SET_BUDGET':
      return {
        ...state,
        info: action.payload.newInfoState
      };
    default:
      return state;
  }
};

export {
  AdaccountReducer,
  listAdAccounts,
  loadAdAccountInfo,
  loadAdAccountInsights,
  getLongLivedAccessToken,
  resetBudget,
  setBudget
};
