const NotificationReducer = (state, action) => {
  switch (action.type) {
    case 'SHOW_NOTIFICATION':
      return {
        ...state,
        type: action.payload.type,
        message: action.payload.message,
        open: true,
      };
    case 'HIDE_NOTIFICATION':
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
};

export default NotificationReducer;
