import { TableRow, TableCell } from '@mui/material';
import { TextRow } from 'react-placeholder/lib/placeholders';
import { number } from 'prop-types';

const TablePlaceholder = ({ rowCount, columnCount }) => [...Array(rowCount)].map((e, i) => (
  <TableRow key={`ph-row-${Date.now() + i}`}>
    {[...Array(columnCount)].map((e1, i2) => (
      <TableCell key={`ph-cell-${Date.now() + i2}`}>
        <TextRow showLoadingAnimation color="#e0e0e0" />
      </TableCell>
    ))}
  </TableRow>
));

TablePlaceholder.propTypes = {
  rowCount: number.isRequired,
  columnCount: number.isRequired
};

export default TablePlaceholder;
