import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Switch,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAppState } from 'src/contexts/AppState';
import ReactPlaceholder from 'react-placeholder/lib';
import TablePlaceholder from '../TablePlaceholder';
import DownloadAdsetButton from './DownloadAdsetButton';

const AdsetListResults = ({
  cid,
  selectedAdsetIds,
  setSelectedAdsetIds,
  adsets,
  handleLoadInfo,
  handleStateChange,
  handleFavorite,
  ...rest
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }));

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [start, setStart] = useState(0);
  const [state] = useAppState();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('stateCol');
  const classes = useStyles();

  const headCells = [
    { id: 'name', numeric: false, label: 'Nume' },
    { id: 'status', numeric: false, label: 'Stare' },
    { id: 'purchases', numeric: false, label: 'Rezultate' },
    { id: 'spent', numeric: true, label: 'Suma cheltuită' },
    { id: 'purchaseValue', numeric: true, label: 'Vânzări' }
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const descendingComparator = (a, b, orderingBy) => {
    if (b[orderingBy] < a[orderingBy]) {
      return -1;
    }
    if (b[orderingBy] > a[orderingBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (ordering, orderingBy) => ((ordering === 'desc') ? (a, b) => descendingComparator(a, b, orderingBy) : (a, b) => -descendingComparator(a, b, orderingBy));

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const ordering = comparator(a[0], b[0]);
      if (ordering !== 0) return ordering;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const handleSelectAll = (event) => {
    let newSelectedAdsetIds;

    if (event.target.checked) {
      newSelectedAdsetIds = adsets.map((adset) => adset.id);
    } else {
      newSelectedAdsetIds = [];
    }

    setSelectedAdsetIds(newSelectedAdsetIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedAdsetIds.indexOf(id);
    let newSelectedAdsetIds = [];

    if (selectedIndex === -1) {
      newSelectedAdsetIds = newSelectedAdsetIds.concat(selectedAdsetIds, id);
    } else if (selectedIndex === 0) {
      newSelectedAdsetIds = newSelectedAdsetIds.concat(selectedAdsetIds.slice(1));
    } else if (selectedIndex === selectedAdsetIds.length - 1) {
      newSelectedAdsetIds = newSelectedAdsetIds.concat(selectedAdsetIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedAdsetIds = newSelectedAdsetIds.concat(
        selectedAdsetIds.slice(0, selectedIndex),
        selectedAdsetIds.slice(selectedIndex + 1)
      );
    }

    setSelectedAdsetIds(newSelectedAdsetIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setStart(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    setStart(newPage * limit);
  };

  useEffect(() => {
    setPage(0);
    setStart(0);
  }, [adsets.length]);

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedAdsetIds.length === adsets.length}
                    color="secondary"
                    indeterminate={
                      selectedAdsetIds.length > 0
                      && selectedAdsetIds.length < adsets.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>
                  &nbsp;
                </TableCell>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? 'right' : 'left'}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={(event) => handleRequestSort(event, headCell.id)}
                    >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <span className={classes.visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <ReactPlaceholder ready={state.adset.loaded} customPlaceholder={<TablePlaceholder rowCount={10} columnCount={8} />}>
                {stableSort(adsets, getComparator(order, orderBy)).slice(start, start + limit).map((adset) => {
                  let adsetActive = false;
                  if (state.adset.loaded) {
                    adsetActive = (adset.status === 'ACTIVE');
                  }

                  return (
                    <TableRow
                      hover
                      key={adset.id}
                      selected={selectedAdsetIds.indexOf(adset.id) !== -1}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedAdsetIds.indexOf(adset.id) !== -1}
                          onChange={(event) => handleSelectOne(event, adset.id)}
                          value="true"
                        />
                      </TableCell>
                      <TableCell>
                        <Box>
                          <Switch color="secondary" checked={adsetActive} onChange={(event) => handleStateChange(adset.id, (event.target.checked === true) ? 'ACTIVE' : 'PAUSED')} name="adsetStatus[]" />
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box>{(state.adset.loaded) ? adset.name : ''}</Box>
                      </TableCell>
                      <TableCell>
                        <Box>{(state.adset.loaded) ? adset.status : ''}</Box>
                      </TableCell>
                      {(adset.insightsLoading || adset.insightsLoaded) ? (
                        <>
                          <TableCell align="center">
                            <ReactPlaceholder showLoadingAnimation type="text" ready={adset.insightsLoaded} rows={1} color="#e0e0e0">
                              <Box>{(adset.insightsLoaded) ? adset.purchases : ''}</Box>
                            </ReactPlaceholder>
                          </TableCell>
                          <TableCell align="right">
                            <ReactPlaceholder showLoadingAnimation type="text" ready={adset.insightsLoaded} rows={1} color="#e0e0e0">
                              <Box>{(adset.insightsLoaded) ? adset.spent : ''}</Box>
                            </ReactPlaceholder>
                          </TableCell>
                          <TableCell align="right">
                            <ReactPlaceholder showLoadingAnimation type="text" ready={adset.insightsLoaded} rows={1} color="#e0e0e0">
                              <Box>{(adset.insightsLoaded) ? adset.purchaseValue : ''}</Box>
                            </ReactPlaceholder>
                          </TableCell>
                        </>
                      ) : (
                        <TableCell colSpan="3" align="center">
                          <DownloadAdsetButton adsetId={adset.id} handleLoadInfo={handleLoadInfo} />
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </ReactPlaceholder>
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={adsets.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 15, 25]}
      />
    </Card>
  );
};

AdsetListResults.propTypes = {
  cid: PropTypes.string.isRequired,
  adsets: PropTypes.array.isRequired,
  selectedAdsetIds: PropTypes.array,
  setSelectedAdsetIds: PropTypes.func,
  handleLoadInfo: PropTypes.func,
  handleStateChange: PropTypes.func,
  handleFavorite: PropTypes.func
};

export default AdsetListResults;
