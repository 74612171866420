import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Toolbar } from '@mui/material';
import Facebook from '../icons/Facebook';

const MainNavbar = (props) => (
  <AppBar
    elevation={0}
    {...props}
  >
    <Toolbar sx={{ height: 64 }}>
      <RouterLink to="/">
        <Facebook style={{ color: '#fff', fontSize: '35px' }} />
      </RouterLink>
    </Toolbar>
  </AppBar>
);

export default MainNavbar;
