import { Snackbar, Alert } from '@mui/material';
import { useAppState } from 'src/contexts/AppState';

const GlobalNotification = () => {
  const [state, dispatch] = useAppState();

  const handleClose = () => {
    dispatch({
      type: 'HIDE_NOTIFICATION'
    });
  };

  return (
    <Snackbar
      open={state.notification.open}
      autoHideDuration={state.notification.duration}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Alert severity={state.notification.type} variant="filled">
        {state.notification.message}
      </Alert>
    </Snackbar>
  );
};

export default GlobalNotification;
